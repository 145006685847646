import {
  curry,
  pathSatisfies,
  test
} from "ramda";

const propFilter = curry((pathList, regex) => pathSatisfies(test(regex), pathList));

/**
 * find data retrieved by file name
 */
export default function getDataByFileName(nodes, regex) {
  const filterByFileName = propFilter(["fields", "fileName"]);

  // node part
  const node = nodes.find(filterByFileName(regex)) || {};

  return {
    node
  };
}
