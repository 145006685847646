import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import SEO from "components/SEO";
import getDataByFileName from "utils/getDataByFileName";
import { Container } from "react-bootstrap";

export const query = graphql`
  query PrivacyQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          header
          subheader
          content
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Privacy = ({ data, pathContext: { langKey } }) => {
  const {
    site: { siteMetadata: { keywords, description } },
    allMarkdownRemark: { nodes },
  } = data;

  const { node } = getDataByFileName(nodes, /privacy/i);

  const { header, subheader, content } = node.frontmatter;

  return (
    <>
      <SEO lang={langKey} title="Privacy" keywords={keywords} description={description} />
      <Container>
        <h1>{header}</h1>
        <h6>{subheader}</h6>
        <p dangerouslySetInnerHTML={{
          __html: content
        }} />
      </Container>
    </>
  );
};

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

Privacy.defaultProps = {
  pathContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Privacy;
